<template>
  <section class="bg-content">
    <section class="container mx-auto px-4 md:px-0 flex items-center justify-between">
      <section class="flex items-center">
        <router-link to="/">
          <Inline class="block md:hidden w-10 text-white fill-current" type="thriv-v" />
          <Inline class="hidden md:block w-32 text-white fill-current" type="thriv" />
        </router-link>

        <router-link :to="{ hash: '#join' }" class="hidden md:flex mx-12 my-5 px-1 font-section uppercase no-underline leading-none text-client border border-client">join our network</router-link>
      </section>

      <!-- !desktop -->
      <section class="flex md:hidden">
        <button class="inline-flex items-center justify-center my-3 rounded-md text-white hover:text-primary focus:outline-none focus:ring-3 focus:ring-insest focus:ring-white" aria-expanded="false" v-on:click="setting">
          <span class="sr-only">open main navigation</span>
          <svg :class="set ? 'hidden' : 'block'" class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg :class="set ? 'block' : 'hidden'" class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </section>

      <!-- desktop -->
      <section class="hidden md:flex items-center">
        <ul class="list-reset flex">
          <li v-for="item in navigation" :key="item.uuid" class="mr-3 p-3 font-section uppercase no-underline"><router-link :to="item.to" class="text-white hover:text-client transition-all ease-in-out duration-300" active-class="text-client">{{ item.name }}</router-link></li>
        </ul>
      </section>
    </section>

    <!-- !desktop -->
    <section :class="set ? 'block' : 'hidden'">
      <section class="py-3 text-center space-y-1">
        <router-link v-for="item in navigation" :key="item.uuid" :to="item.to" class="font-section uppercase no-underline px-3 py-1 text-white hover:text-client transition-all ease-in-out duration-300" active-class="text-client">{{ item.name }}</router-link>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default defineComponent({
  name: `Application`,
  data() {
    return {
      set: false,
      navigation: [
        // { uuid: 1, to: '/talent', name: 'talent'},
        { uuid: 3, to: '/client', name: 'client'},
        // { uuid: 5, to: '/about', name: 'about'},
        // { uuid: 7, to: '/article', name: 'article'}
      ]
    }
  },
  methods: {
    setting(): any {
      this.set = !this.set
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
