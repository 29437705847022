
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default defineComponent({
  name: `Application`,
  data() {
    return {
      set: false,
      navigation: [
        // { uuid: 1, to: '/talent', name: 'talent'},
        { uuid: 3, to: '/client', name: 'client'},
        // { uuid: 5, to: '/about', name: 'about'},
        // { uuid: 7, to: '/article', name: 'article'}
      ]
    }
  },
  methods: {
    setting(): any {
      this.set = !this.set
    }
  }
})
