<template>
  <section class="py-10 bg-content">
    <footer class="container mx-auto px-4 md:px-0 md:flex md:justify-between text-white">
      <section class="flex flex-col">
        <router-link to="/">
          <Inline class="mb-3 w-20 fill-current" type="thriv" />
        </router-link>
        <section class="flex md:hidden flex-col">
          <ul class="list-reset flex flex-col">
            <li class="pb-3 font-thin text-sm no-underline"><p class="">Annankatu 34B, 00100 Helsinki, Finland</p></li>
            <li class="pt-3 pb-3 font-thin text-sm no-underline"><router-link to="/privacy" class="hover:text-client transition-all ease-in-out duration-300" active-class="text-client">Privacy Policy</router-link></li>
          </ul>
        </section>
        <section class="hidden mt-3 md:flex items-center">
          <Inline class="w-16 fill-current" type="futurice_family" />
          <ul class="list-reset flex flex-col">
            <li v-for="item in navigation" :key="item.uuid" class="pl-3 font-thin text-sm capitalize no-underline"><a :href="item.to" class="hover:text-client transition-all ease-in-out duration-300" target="_blank" rel="noreferrer">{{ item.name }}</a></li>
          </ul>
        </section>
      </section>
      <section class="flex mt-3 md:hidden items-center justify-between">
        <ul class="list-reset flex flex-col">
          <li v-for="item in navigation" :key="item.uuid" class="font-thin text-sm capitalize no-underline"><a :href="item.to" class="hover:text-client transition-all ease-in-out duration-300" target="_blank" rel="noreferrer">{{ item.name }}</a></li>
        </ul>
        <Inline class="w-16 fill-current" type="futurice_family" />
      </section>
      <section class="hidden md:flex flex-col">
        <ul class="list-reset flex flex-col">
          <li class="pb-3 font-thin text-sm no-underline"><a href="//docs.google.com/document/d/1cDd3Dj3BmPtattyB3-Gxd_cR7-LwYAcMgtqeVIKn--c/edit?usp=sharing" target="_blank" rel="noreferrer" class="hover:text-client transition-all ease-in-out duration-300" active-class="text-client">Privacy Policy</a></li>
          <li class="pt-3 font-medium text-sm no-underline"><p class="">Thriv Oy</p></li>
          <li class="font-thin text-sm no-underline"><p class="">Vuorikatu 14A, 00100 Helsinki, Finland</p></li>
        </ul>
      </section>
    </footer>
    <section v-if="copyright" class="pt-10 container mx-auto flex flex-col items-center text-grey">
      <p class="font-thin text-xs uppercase">made with <span class="text-tertiary">&lt;3</span> in helsinki</p>
      <p class="font-thin text-tiny uppercase">copyright thriv {{ copyright_ }}</p>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import { Temporal } from 'proposal-temporal'

export default defineComponent({
  name: `ApplicationFooter`,
  data() {
    return {
      copyright: false,
      copyright_: Temporal.now.date().year,
      navigation: [
        { uuid: 1, to: '//futurice.com', name: 'futurice'},
        { uuid: 3, to: '//columbiaroad.com', name: 'columbia road'},
        { uuid: 5, to: '//meltlake.com', name: 'meltlake'}
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
