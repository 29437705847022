
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import { Temporal } from 'proposal-temporal'

export default defineComponent({
  name: `ApplicationFooter`,
  data() {
    return {
      copyright: false,
      copyright_: Temporal.now.date().year,
      navigation: [
        { uuid: 1, to: '//futurice.com', name: 'futurice'},
        { uuid: 3, to: '//columbiaroad.com', name: 'columbia road'},
        { uuid: 5, to: '//meltlake.com', name: 'meltlake'}
      ]
    }
  }
})
